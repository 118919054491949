import { TokenService } from '@/services/token';
import { UserService, AuthenticationError } from '@/services/user';
import router from '@/router/router';
import { getRoleByFeatures, rolesTypes } from '@/utils/userRoles';

// initial state for authentication
const state = {
  authenticating: false,
  authenticationError: false,
  user: false,
  lastUsedBotId: null,
};

const getters = {
  loggedIn: () => {
    return TokenService.getToken() ? true : false;
  },

  authenticationError: (state) => {
    return state.authenticationError;
  },

  authenticating: (state) => {
    return state.authenticating;
  },

  user: (state) => {
    return state.user;
  },

  isAdmin: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'admin');
    }

    return false;
  },
  isOwner: (state) => {
    if (!state.user?.features) return false;
    const role = getRoleByFeatures(state.user?.features);
    return role === rolesTypes.OWNER;
  },
  isAssistant: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature ===  rolesTypes.ASSISTANT);
    }

    return false;
  },
  isRAG: (state, getters, rootState, rootGetters) => {
    const ragEnabled = rootGetters['intents/fallbackFaqIntentEnabled'];

    if (ragEnabled === true) {
      return state.user.features.some((feature) => feature === rolesTypes.RAG || feature === rolesTypes.ADMIN || feature === rolesTypes.OWNER || feature === 'simpleEdit');
    }

    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === rolesTypes.RAG || feature === rolesTypes.ADMIN);
    }

    return false;
  },
  isTester: (state) => {
    if (!state.user?.features) return false;
    const role = getRoleByFeatures(state.user?.features);
    return role === rolesTypes.TESTER;
  },
  isNLPOwner: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'nlp');
    }

    return false;
  },
  isFormView: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some((feature) => feature === 'formView') ||
        getters.isAdmin(state)
      );
    }

    return false;
  },
  isWidgetEditorView: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some((feature) => feature === 'widgetEditorView') ||
        getters.isAdmin(state)
      );
    }

    return false;
  },
  isConversationalView: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some(
          (feature) => feature === 'conversationalView'
        ) || getters.isAdmin(state)
      );
    }

    return false;
  },
  isChatInsights: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some((feature) => feature === 'chatInsights') ||
        getters.isAdmin(state)
      );
    }

    return false;
  },
  isOnlyStaging: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'onlyStaging');
    }

    return false;
  },

  isNoBeamer: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'noBeamer');
    }

    return false;
  },

  isSimpleEdit: (state) => {
    if (getters.isAdmin(state) || getters.isOwner(state)) return true;

    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'simpleEdit');
    }

    return false;
  },

  isChannelEdit: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some((feature) => feature === 'channelEdit') ||
        getters.isAdmin(state)
      );
    }

    return false;
  },

  isSuggestion: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return state.user.features.some((feature) => feature === 'suggestion');
    }

    return false;
  },
  isNewIntent: (state) => {
    if (
      state.user &&
      state.user.features &&
      Array.isArray(state.user.features)
    ) {
      return (
        state.user.features.some((feature) => feature === 'newIntent') ||
        getters.isAdmin(state)
      );
    }

    return false;
  },
  isMultiBot: (state) => {
    return state.user?.features.includes('multibot') ? true : false;
  },
  isLivechat: (state) => {
    return state.user?.features.includes('livechat') ? true : false;
  },
  isSupervisor: (state) => {
    return state.user?.features.includes('supervisor') ? true : false;
  },

  lastUsedBotId: (state) => state.lastUsedBotId,
};

const actions = {
  async loginReset({ commit }) {
    commit('loginReset');
  },
  async login({ commit, dispatch }, { username, password }) {
    // login request and set state
    commit('loginRequest');

    try {
      const { token, two_fa } = await UserService.login(username, password);

      if (token && !two_fa) {
        commit('loginSuccess', token);
        const userObject = UserService.decodeToken(token);
        commit('setUser', userObject);
        // Redirect the user to the page he first tried to visit or to the home view
        // load bots and set first one as active
        dispatch('bots/get', null, { root: true }).then(() => {
          router.push(router.history.current.query.redirect || '/');
        });
      } else {
        //2FA auth required
        commit('loginSuccess');
        const redirectParam = router?.history?.current?.query?.redirect
          ? `?redirect=${router.history.current.query.redirect}`
          : '';
        router.push(`/2fa/${token}${redirectParam}`);
        return false;
      }

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode });
      } else {
        commit('loginError', { errorCode: 401 });
      }

      return false;
    }
  },
  async twofaLogin({ commit, dispatch }, { twofa_token, usertoken }) {
    // login request and set state
    commit('loginRequest');

    try {
      const token = await UserService.twofaLogin(twofa_token, usertoken);
      if (token) {
        commit('loginSuccess', token);
        const userObject = UserService.decodeToken(token);
        commit('setUser', userObject);
        dispatch('bots/get', null, { root: true }).then(() => {
          // Redirect the user to the page he first tried to visit or to the home view
          router.push(router.history.current.query.redirect || '/');
        });
      } else {
        commit('loginError', { errorCode: 100 });
        return false;
      }

      return true;
    } catch (e) {
      if (e instanceof AuthenticationError) {
        commit('loginError', { errorCode: e.errorCode });
      } else {
        commit('loginError', { errorCode: 401 });
      }

      return false;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async requestPasswordReset({ commit }, { username }) {
    try {
      const response = UserService.requestPasswordReset(username);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async setNewPassword({ commit }, { username, password, token }) {
    // No catch here because we need the 400 error response in the component
    const response = await UserService.setNewPassword(
      username,
      password,
      token
    );
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async requestInvite({ commit }, { email, bot }) {
    // No catch here because we need the 400 error response in the component
    const response = await UserService.requestInvite(email, bot);
    return response;
  },
  // eslint-disable-next-line no-unused-vars
  async completeInvite({ commit }, { username, password, token }) {
    // No catch here because we need the 400 error response in the component
    const response = await UserService.completeInvite(
      username,
      password,
      token
    );
    return response;
  },

  // @ payload { reload: boolean }
  logout({ commit, dispatch }, payload) {
    UserService.logout();
    // call the livechat store
    dispatch('livechat/goOffline', null, { root: true });
    commit('logoutSuccess');
    if (payload?.reload) {
      window.location.reload(true);
    } else {
      // goto login page
      router.push('/login');
    }
  },

  async getUser({ commit, dispatch }, {isPublic = false} = {}) {
    try {
      const token = TokenService.getToken();
      const userObject = UserService.decodeToken(token);

      // Hack for language switch
      // (user.language is not changed directly. Need logout/login. Only auth/me gives current language.)
      const { data } = await UserService.getUser();
      
      if (!isPublic && (data === null || !data)) {
        return dispatch('logout');
      }

      if (data?.language) {
        userObject.language = data.language;
      }
      if (data?.features) {
        userObject.features = data.features;
      }
      commit('setUser', userObject);

      return true;
    } catch (e) {
      if (isPublic) {
        return true;
      }

      return dispatch('logout');
    }
  },

  async saveLanguage({ dispatch, state }, language) {
    await UserService.saveLanguage({ username: state.user.username, language });
    dispatch('getUser');
    return true;
  },

  /**
   * Handle OAuth SSO Callback
   * @param commit
   * @param dispatch
   * @param {string} provider Name of the ID provider, e.g. "microsoft"
   * @param {Object} data Data from the callback URL, put into our query strings
   * @param {?{redirect: boolean, autoLogin: boolean}} [options={redirect: true, autoLogin: true}] Options for the login
   * @returns {Promise<{success: boolean, error?: string}>}
   */
  async handleSSOCallback({ commit, dispatch }, { provider, data, options }) {
    Object.assign({
      autoLogin: true,
      redirect: true,
    }, options);

    commit('loginRequest');

    try {
      const result = await UserService.handleSSOCallback(provider, data, options);
      if (result.error) throw new Error(result.error);

      // 2FA is required
      if (result.two_fa) {
        commit('loginSuccess');
        const redirectParam = router?.history?.current?.query?.redirect
          ? `?redirect=${router.history.current.query.redirect}`
          : '';

        router.push(`/2fa/${result.token}${redirectParam}`);
        return {sucess: true};
      }

      // 2FA is not required
      commit('loginSuccess', result.token);
      const userObject = UserService.decodeToken(result.token);
      commit('setUser', userObject);
      // Redirect the user to the page he first tried to visit or to the home view
      // load bots and set first one as active
      dispatch('bots/get', null, { root: true })
        .then(() => {
          if (options.redirect && options.autoLogin) {
            router.push(router.history.current.query.redirect || '/');
          }
        });

      return {success: true};
    } catch(err) {
      return {
        success: false,
        error: err.message ?? err.toString(),
      };
    }
  }
};

const mutations = {

  loginRequest(state) {
    // login is requested
    state.authenticating = true;
    state.authenticationError = false;
  },

  loginSuccess(state) {
    // login was successfull
    state.authenticating = false;
  },

  loginError(state, { errorCode }) {
    // login had an error
    state.authenticating = false;
    state.authenticationError = errorCode === 401 ? true : true;
  },

  loginReset(state) {
    state.authenticating = false;
    state.authenticationError = false;
  },

  logoutSuccess(state) {
    // logout
    state.user = false;
  },

  setUser(state, user) {
    state.user = user;
  },

  setLastUsedBotId(state, botId) {
    state.lastUsedBotId = botId;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
